<template lang="html">

    <section class="paginator">
        <button type="button" class="prev-page" title="Ir a la página anterior" @click="goPrev" v-show="this.pageActual > 1"></button>
        <div class="container-select">
            <select name="historico" id="historico" v-if="totalItems" class="w-100" @change="gotopage(item)" v-model="pageSelected">
                <option v-for="(item, key) in totalItems" :key="key" :value="item" :selected="pageActual">Página {{item}} de {{totalItems}}</option>
            </select>
        </div>
        <button type="button" class="next-page" title="Ir a la página siguiente" @click="goNext" v-show="this.pageActual < this.totalItems"></button>
    </section>
</template>

<script lang="js">
    export default {
        name: 'paginator',
        props: ['pageActual', 'totalItems'],
        mounted() {

        },
        data() {
            return {
                pageSelected: this.pageActual
            }
        },
        methods: {
            gotopage(item) {
                this.$emit('changed', this.pageSelected);
            },
            goNext(item) {
                this.$emit('clicked', this.pageActual + 1);
            },
            goPrev(item) {
                this.$emit('clicked', this.pageActual - 1);
            }

        },
        watch: {
            'pageActual'() {
                this.pageSelected = this.pageActual
            }

        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";
    @import "@/styles/general.scss";

    .paginator {
        padding: 20px;
        margin: 40px 0 0 0;
        border-top: 1px solid $blueSuperLight;
        display: flex;
        justify-content: center;
        align-items: center;

        .container-select select {
            background-color: $blue;
            color: white;
            background-image: url(/img/icons/arrow-down.svg);
        }

        .next-page {
            background-color: transparent;
            border: 0;
            background-image: url(/img/icons/arrow-right-blue.svg);
            font-size: 0;
            width: 30px;
            height: 30px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 25px;
            cursor: pointer;
            margin-left: 15px;
        }

        .prev-page {
            background-color: transparent;
            border: 0;
            background-image: url(/img/icons/arrow-right-blue.svg);
            font-size: 0;
            width: 30px;
            height: 30px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 25px;
            cursor: pointer;
            transform: rotate(180deg);
            margin-right: 15px;
        }

        .page-item {
            font-family: "Frank Ruhl Libre", serif;
            color: black;
            font-size: 20px;
            background: transparent;
            border: 0;
            margin: 0 5px;
            cursor: pointer;

            &.active {
                display: block;
                font-weight: bold;
                border-radius: 50%;
                font-size: 22px;
            }
        }

    }



    @media screen and (max-width:845px) {
        .paginator {
            margin: 20px 0;
        }
    }
</style>